<template>
    <section>
        <transition name="fade" mode="out-in">
            <router-view :isLatestNews.sync="isLatestNews" />
        </transition>
        <site-footer />
    </section>
</template>

<script>
    import Footer from '@/components/Footer'

    export default {
        components: {
            'site-footer': Footer
        },
        data() {
            return {
                isLatestNews: true
            }
        },
        mounted() {
            this.$sectionLoaded();
        }
    }
</script>